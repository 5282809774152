import React, { useState } from 'react';
import axios from 'axios';
import { Tv } from 'lucide-react';
import { titleCase } from "title-case";
import Sloganator from './sloganator.js';
import { Analytics } from "@vercel/analytics/react";

const ModernSpaceUI = () => {
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('');
  const [result, setResult] = useState('');
  const [images, setImages] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setResult('');
    setStatus('');
    setImages([]);

    try {
      // Initial POST request
      const response = await axios.post('https://let-me-finish-that-for-you-service-vg6m6t45da-wm.a.run.app/let_me_finish_please', { query });
      // const response = await axios.post('http://0.0.0.0:8080/let_me_finish_please', { query });
      const { process_id } = response.data;

      // Set up EventSource for status updates
      const eventSource = new EventSource(`https://let-me-finish-that-for-you-service-vg6m6t45da-wm.a.run.app/status/${process_id}`);
      // const eventSource = new EventSource(`http://0.0.0.0:8080/status/${process_id}`);

      eventSource.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.status === 'Complete') {
          setResult(data.result.synopsis);
          setImages(data.result.images.map(img => `data:image/png;base64,${img}`));
          setLoading(false);
          eventSource.close();
        } else {
          setStatus(data.status);
        }
      };

      eventSource.onerror = (error) => {
        console.error('EventSource failed:', error);
        setLoading(false);
        setStatus('An error occurred');
        eventSource.close();
      };
    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
      setStatus('An error occurred while initiating the process');
    }
  };

  const getStepNumber = (status) => {
    const steps = [
      'Starting process',
      'Bingeing the series...',
      'Thinking hard about what I just watched...',
      'Locking the writers in the writers\' room',
      'Hammering out the final season outline',
      'Visualizing all the things',
      'Pulling synopsis of the final season from an alternate timeline'
    ];
    const index = steps.findIndex(step => step === status);
    return index !== -1 ? index + 1 : 1;
  };

  const renderSynopsisWithImages = () => {
    if (!result || images.length < 3) return null;

    const paragraphs = result.split('\n\n');
    const midPoint = Math.floor(paragraphs.length / 2);

    return (
      <div className='space-y-4'>
        <img src={images[0]} alt="Generated 1" className="w-full rounded-lg mb-4" />
        {paragraphs.map((paragraph, index) => (
          <React.Fragment key={index}>
            <p className="text-gray-900 whitespace-pre-wrap">{paragraph}</p>
            {index === Math.floor(midPoint / 2) && (
              <img src={images[1]} alt="Generated 2" className="w-full rounded-lg my-4" />
            )}
            {index === midPoint && (
              <img src={images[2]} alt="Generated 3" className="w-full rounded-lg my-4" />
            )}
          </React.Fragment>
        ))}
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-violet-600 to-indigo-600 flex items-center justify-center p-4">
      <div className="w-full max-w-md">
        <h1 className="text-4xl font-bold text-white mb-8 text-center">Let Me Finish<br/>That for You</h1>
        <Analytics />
        {!loading && !result && (
          <div className="mb-8">
            <Sloganator />
          <form onSubmit={handleSubmit} className="space-y-4">
            <input
              type="text"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Enter TV series name"
              className="w-full p-3 rounded-full bg-white-800 text-black placeholder-gray-400 border border-gray-700 focus:outline-none focus:border-blue-500"
            />
            <button
              type="submit"
              className="w-full p-3 rounded-full bg-pink-500 text-white font-semibold hover:bg-pink-600 transition duration-300 flex items-center justify-center"
            >
              <Tv className="mr-2" size={20} />
              Finish It
            </button>
          </form>
          </div>
        )}
        
        {loading && (
          <div className="text-center">
            <p className="text-xs text-white mb-4">Finding closure... (it takes about a minute)</p>
            <div className="w-full bg-gray-700 rounded-full h-2.5 mb-4">
              <div 
                className="bg-pink-600 h-2.5 rounded-full transition-all duration-500 ease-out" 
                style={{ width: `${(getStepNumber(status) / 8) * 100}%` }}
              ></div>
            </div>
            <p className="text-m text-gray-200">{status}</p>
          </div>
        )}
        
        {result && (
          <div className="bg-white rounded-lg p-6 text-white">
            <h2 className="text-gray-900 text-2xl font-semibold mb-4">{titleCase(query)}</h2>
            {renderSynopsisWithImages()}
            <button
              onClick={() => {setResult(''); setQuery(''); setStatus('');}}
              className="mt-4 w-full p-3 rounded-full bg-pink-500 text-white font-semibold hover:bg-pink-600 transition duration-300"
            >
              Again!
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ModernSpaceUI;