import React, { useState, useEffect } from 'react';

const slogans = [
  "Providing closure for those series canceled too soon.",
  "Giving voice to stories silenced too early.",
  "Amplifying the echoes of shows cut short.",
  "Resurrecting narratives: A voice for the prematurely canceled.",
  "Speaking for the silent: Finales for canceled classics.",
  "Unlocking the potential of shows stopped mid-sentence.",
  "Crafting finales for the stories left untold",
  "Bringing closure to your favorite unfinished series",
  "Imagining the endings that never were",
  "Where cancelled shows find their final act",
  "Completing the narrative: Your show's ultimate closure",
  "From cliffhanger to conclusion: Finishing your favorite series",
  "Turning 'what ifs' into satisfying finales",
  "Because every great story deserves an ending",
  "Finishing the show that never got finished",
  "Where cancelled shows find their final act",
  "Providing a voice for those series canceled too early"
];

const Sloganator = () => {
  const [slogan, setSlogan] = useState('');

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * slogans.length);
    setSlogan(slogans[randomIndex]);
  }, []);

  return (
    <p className="text-xl font-regular text-white mb-8 text-center">{slogan}</p>
  );
};

export default Sloganator;